/*----------------------------------------*/
/*    07. Component - Custom Animation
/*----------------------------------------*/
.swiper-slide-active{
    .text-slider{
        &.splitting .char {
            animation: slide-in-left .2s cubic-bezier(.5, 0, .5, 1) both;
            animation-delay: calc(60ms * var(--char-index));
        }
    }
    .splitting .char {
        animation: slide-in 1s cubic-bezier(.5, 0, .5, 1) both;
        animation-delay: calc(60ms * var(--char-index));
    }
    @keyframes slide-in {
        from {
          transform: translateY(1em) rotate(-.5turn) scale(0.5);
          opacity: 0;
        }
    }
    @keyframes slide-in-left {
        from {
          transform: translateY(0em);
          opacity: 0;
        }
    }
}
.aos-animate{
    &.splitting .char {
        animation: slide-in-1 1s cubic-bezier(.5, 0, .5, 1) both;
        animation-delay: calc(60ms * var(--char-index));
    }
    &.splitting .char {
        animation: slide-in-2 1s cubic-bezier(.5, 0, .5, 1) both;
        animation-delay: calc(60ms * var(--char-index));
    }
    @keyframes slide-in-1 {
        from {
          transform: translateX(-1em) rotate(-.5turn) scale(0.5);
          opacity: 0;
        }
    }
    @keyframes slide-in-2 {
        from {
          transform: translateX(1em) rotate(0turn) scale(0.5);
          opacity: 0;
        }
    }
}
/*-- Slider Animation --*/
.slider-content>* {
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-name: fadeOutUp;
}
.swiper-slide-active{
    .slider-content>:nth-child(1){
        animation-delay: .5s;
        animation-name: fadeInUp;
    }
    .slider-content>:nth-child(2){
        animation-delay: .9s;
        animation-name: fadeInUp;
    }
    .slider-content>:nth-child(3){
        animation-delay: 1.3s;
        animation-name: fadeInUp;
    }
    .slider-content>:nth-child(4){
        animation-delay: 1.6s;
        animation-name: fadeInUp;
    }
}
/*-- Video Home Animation --*/