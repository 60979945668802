/*----------------------------------------*/
/*    03. Base - Helper 
/*----------------------------------------*/
/* --- Preloader---*/
#preloader{
	position: fixed;
	top: 0; 
	bottom: 0; 
	left: 0; 
	right: 0;
	margin: auto;
	z-index: 999;
	background-color: $white;
}
.preloader{
	display: inline-block;
    padding: 0px;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
/* --- Section Content ---*/
.desc-content{
	margin-bottom: -5px;
}
/* --- Section title ---*/
.section-title{
	.title{
		font-size: 40px;
		color: $headings-color;
		line-height: 1.2;
		margin-top: -10px;
		text-transform: inherit;
		font-weight: 600;
		@include breakpoint(max-sm_device){
			font-size: 36px;
		}
		@include breakpoint(max-xs_device){
			font-size: 32px;
		}
		@include breakpoint(max-xxs_device){
			font-size: 26px;
		}
	}	
	.sub-title{
		font-size: 18px;
		color: $body-color;
		margin-bottom: 10px;
		font-weight: 500;
		max-width: 655px;
		margin: 0 auto;
		@include breakpoint(max-xl_device){
			max-width: 500px;
		}
		@include breakpoint(max-xs_device){
			font-size: 16px;
		}
	}
}
/* ---Spacing Between Two Row---*/
.pt-90{
	padding-top: 90px;
	@include breakpoint(max-sm_device){
		padding-top: 50px;
	}
}
.pb-90{
	padding-bottom: 90px;
	@include breakpoint(max-sm_device){
		padding-bottom: 50px;
	}
}
.mt-90{
	margin-top: 90px;
	@include breakpoint(max-sm_device){
		margin-top: 50px;
	}
}
.section-padding{
	padding: 50px 0;
}
.mt-30{
	margin-top: 30px;
	@include breakpoint(max-xxs_device){
		margin-top: 15px;
	}
}
/*-- Scroll To Top --*/
.scroll-top{
    position: fixed;
    right: 30px;
    bottom: -60px;
    z-index: 999;
    box-shadow: 0 30px 50px rgba(0,0,0,.03);
    display: block;
    padding: 0;
    width: 50px;
    height: 50px;
    text-align: center;
    font-size: 25px;
    line-height: 50px;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    background-color: $headings-color;
    background-size: 200% auto;
    background-position: left center;
    color: $white;
    transition: all .25s cubic-bezier(.645,.045,.355,1);
    overflow: hidden;
	&.show{
		visibility: visible;
		opacity: 1;
		bottom: 60px;
	}
	&:hover{
		background-position: right center;
		background-color: $primary;
		.arrow-top {
			transform: translate(-50%,-80px);
		}
		.arrow-bottom {
			transform: translate(-50%,-50%);
		}
	}
	.arrow-top {
		transform: translate(-50%,-50%);
	}
	.arrow-bottom {
		transform: translate(-50%,80px);
	}
	i {
		position: absolute;
		top: 50%;
		left: 50%;
		color: $white;
		transition: all .25s cubic-bezier(.645,.045,.355,1);
	}
}
/* ---Social Links --- */
.widget-social{
	a{
		font-size: 16px;
		height: 35px;
		width: 35px;
		line-height: 35px;
		text-align: center;
		background: $gray-200;
		color: $headings-color;
		margin-right: 10px;
		border-radius: 50%;
		&:hover{
			background: $primary;
			color: $white;
		}
		@include breakpoint(max-xxs_device){
			margin-right: 5px;
		}
	}
}
/*--- Image Hover Style ---*/
.hover-style{
	display: block;
	overflow: hidden;
	img{
		transform: scale(1.01) rotate(.05deg);
        transition: all .6s ease-in-out;
	}
	.overlay-1{
		position: absolute;
		left: 0;
		top: 0;
		opacity: 0;
		content: "";
		background-color: rgb(0, 0, 0);
		width: 100%;
		height: 100%;
        transition: .3s;
	}
	&:hover{
		img{
			transform: scale(1.05) rotate(.05deg);
            transition: all .6s ease-in-out;
		}
		.overlay-1{
			opacity: .7;
			transition: .3s;
		}
	}
}
.fit-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
/* ---Nice Select Customization--- */
.myniceselect {
	&.nice-select {
		padding: 0;
		margin: 0;
		height: auto;
		line-height: auto;
		span {
			height: 40px;
			line-height: 40px;
			display: inline-block;
			padding: 0 20px;
		}
		.list {
			width: 100%;
		}
		&.wide{
			span{
				width: 100%;
			}
		}
	}
}
/*--- Swiper Pagination Style ---*/
.swiper-pagination{
	&.default-pagination{
		position: relative;
		bottom: 0px;
		line-height: 14px;
		.swiper-pagination-bullet{
			width: 10px;
			height: 10px;
			background: $gray-500;
			opacity: 1;
			transition: .3s;
			outline: 0px;
			&.swiper-pagination-bullet-active{
				background: $primary;
				border-color: $primary;
				transition: .3s;
			}
			&:hover{
				background: $primary;
				border-color: $primary;
				transition: .3s;
			}
			@include breakpoint(max-md_device){
				display: inline-block;
				width: 12px;
				height: 12px;
			}
		}
	}
} 
/*--- Swiper Navigation Style ---*/
.intro11-carousel-wrap{
    .swiper-button-prev, .swiper-button-next{
        &:focus{
            border: 0px;
            outline: 0px;
        }
        &:after{
            display: none;
        }
        i{
            font-size: 30px;
            color: $headings-color;
            &:hover{
                color: $primary;
			}
			@include breakpoint(max-xs_device){
				font-size: 20px;
			}
		}
		opacity: 0;
		visibility: hidden;
		transition: .3s;
		transition-duration: 400ms;
    }
    .swiper-button-prev{
		left: 50px;
		@include breakpoint(max-lg_device){
			left: 20px;
		}
    }
    .swiper-button-next{
        right: 50px;
		@include breakpoint(max-lg_device){
			right: 20px;
		}
	}
	&:hover{
		.swiper-button-prev, .swiper-button-next{
			opacity: 1;
			visibility: visible;
			transition: .3s;
			transition-duration: 400ms;
		}
	}
	&.arrow-style-2{
		.swiper-button-prev{
			left: 1px;
			@include breakpoint(max-lg_device){
				left: 1px;
			}
		}
		.swiper-button-next{
			right: 0px;
			@include breakpoint(max-lg_device){
				right: 0px;
			}
		}
	}
	&.arrow-style-3{
		.swiper-button-prev{
			left: 15px;
			@include breakpoint(max-sm_device){
				left: 1px;
			}
		}
		.swiper-button-next{
			right: 15px;
			@include breakpoint(max-sm_device){
				right: 0px;
			}
		}
	}
}
/* ---Bootstrap No Gutters--- */
.no-gutters{
	margin-right: 0px;
	margin-left: 0px;
}
// Rating Box
.product-rating{
	i{
		font-size: 16px;
		color: $primary;
	}
}
/* --- Pagination --- */
.pagination{
	& li{
		display: inline-block;
		width: 40px;
		height: 40px;
		line-height: 40px;
		text-align: center;
		background: $gray-200;
		border-radius: 3px;
		margin-left: 8px;
		&:first-child{
			margin-left: 0;
		}
		& a{
			display: block;
			border-radius: 3px;
			color: $headings-color;
			&:hover{
				background: $primary;
				color: $white; 
			}
			
		}
		&.current{
			background: $primary;
			color: $white;
		}
		&.next{
			width: 40px;
		}
	}
}
/* ---Google Maps--- */
.contact-map{
	height: 500px;
	width: 100%;
	display: block;
	@include breakpoint(max-sm_device){
		height: 400px;
	}
}
/* --- Quantity --- */