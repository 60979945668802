/*-----------------------------------------------------------------------------------

    Template Name: Busing - Business Consulting Bootstrap 5 Template
    Version: 1.0

-----------------------------------------------------------------------------------
    
    CSS INDEX
    ===================
    
    01. Bootstrap v5.0.0-alpha2
    02. Template default CSS
    03. Base - Helper
    04. Component - Header
    05. Component - Slider
    06. Component - Button
    07. Component - Custom Animation
    08. Component - Funfact
    09. Component - Testimonial
    10. Component - Support
    11. Component - Breadcrumb
    12. Component - Footer
    13. Pages - About
    14. Pages - Blog
    15. Pages - Portfolio
    16. Pages - Service
    17. Pages - Contact

-----------------------------------------------------------------------------------*/
@import "./bootstrap/functions";
@import "variabls";
@import "custom-variables";
@import "./bootstrap/bootstrap";

@import "common";

@import "base/mixins";
@import "base/helper";
@import "component/header";
@import "component/slider";
@import "component/button";
@import "component/custom-animation";
@import "component/funfact";
@import "component/testimonial";
@import "component/support";
@import "component/breadcrumb";
@import "component/footer";
@import "pages/about";
@import "pages/blog";
@import "pages/portfolio";
@import "pages/service";
@import "pages/contact";