/*----------------------------------------*/
/*    11. Component - Breadcrumb
/*----------------------------------------*/
.breadcrumbs-area{
    padding: 100px 0;
    background: $gray-200;
    // background: #f6f6f6 url(../images/bg/1-1.jpg) no-repeat scroll center center / cover;
    .breadcrumb-content{
        .title{
            font-size: 32px;
            font-weight: 600;
            letter-spacing: 1.2px;
            color: $headings-color;
        }
        ul{
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-top: 20px;
            li{
                color: $headings-color;
                position: relative;
                a{
                    font-size: 18px;
                    color: $headings-color;
                    font-weight: 400;
                    line-height: 1;
                    &:hover{
                        color: $primary;
                    }
                }
                &:after{
                    color: $headings-color;
                    content: '>';
                    font-size: 16px;
                    vertical-align: middle;
                    padding: 0 10px;
                    font-weight: 400;
                }
                &:last-child{
                    &:after{
                        display: none;
                    }
                }
            }
        }
    }
    @include breakpoint(max-sm_device){
        padding: 50px 0px;
    }
}