/*----------------------------------------*/
/*    14. Pages - Blog
/*----------------------------------------*/
.single-blog-post-wrap {
    padding: 0;
    margin-bottom: -5px;
    .blog-thumbnail {
        .btn-plus {
            height: 0;
            width: 0;
            color: #fff;
            position: absolute;
            left: 50%;
            top: 50%;
            transition: 0.4s;
            transform: translate(-50%, -50%) scale(0);
            z-index: 2;
            img {
                width: 50px;
            }
        }
        a{
            position: relative;
            display: block;
            &:before{
                background-color: rgba(0, 0, 0, 0.3);
                content: '';
                position: absolute;
                opacity: 0;
                visibility: hidden;
                left: 0;
                top: 0;
                transform: scaleX(0);
                transition: 0.4s;
                height: 100%;
                width: 100%;
                z-index: 2;
            }
            &:hover{
                &:before{
                    transform: scaleX(1);
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
    &:hover{
        .blog-thumbnail{
            .btn-plus {
                height: 40px;
                width: 40px;
                transform: translate(-50%, -50%) scale(1);
            }
        }
    }
    .blog-post-details{
        .title{
            font-weight: 700;
            font-size: 24px;
            line-height: 1;
            color: $headings-color;
            @include breakpoint(max-md_device){
                font-size: 22px;
            }
        }
        .post-meta{
            color: $headings-color;
            a{
                margin-right: 10px;
                &:last-child{
                    margin-right: 0;
                }
            }
            i{
                color: $headings-color;
            }
        }
    }
}
/*-- Blog Details --*/
.blog-details {
    .image {
        img {
            width: 100%;
            transition: $baseTransition; 
        }
    }
    .content{
        .title {
            font-size: 30px;
            font-weight: 700;
            color: $headings-color;
            @include breakpoint(max-xs_device){
                font-size: 26px;
            }
            @include breakpoint(max-xxs_device){
                font-size: 22px;
            }
        }
        .meta-list {
            display: flex;
            flex-wrap: wrap;
            color: $gray-600; 
            .meta-item {
                font-size: 14px;
                margin-right: 10px;
                &:last-child{
                    margin-right: 0;
                }
                & + .meta-item{
                    &::before{
                        content: "|";
                        margin-right: 10px; 
                    }
                }
            }
            a{
                &:hover{
                    color: $primary;
                }
            }
        }
    }
    .blockquote {
        font-size: 16px;
        line-height: 2;
        position: relative;
        background-color: $gray-100;
        color: $headings-color;
        font-weight: 600;
        padding: 50px 35px 35px;
        &:before{
            background-color: $primary;
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 5px;
        }
        &:after{
            content: "\f10e";
            color: $gray-400;
            font-family: "FontAwesome";
            font-size: 30px;
            position: absolute;
            top: 15px;
            right: 25px;
            @include breakpoint(max-xs_device){
                font-size: 20px;
                top: 30px;
                right: 15px;
            }
        }
        .blockquote-footer {
            color: $gray-700;
            font-size: 15px;
            font-weight: 400;
        }
    }
    .share-article{
        text-align: center;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left-side{
            float: left;
        }
        .right-side{
            float: right;
        }
        h6{
            @include breakpoint(max-xxs_device){
                display: none;
            }
        }
    }
}
.comment-area-wrapper{
    .title{
        font-size: 24px;
        font-weight: 700;
        color: $headings-color;
    }
    .single-comment-wrap{
        &.comment-reply{
            padding-left: 50px;
            @include breakpoint(max-xxs_device){
                padding-left: 20px;
            }
        }
        .author-thumb{
            margin-bottom: 0;
            a{
                width: 80px;
                height: 80px;
                margin-right: 10px;
                img{
                    width: 100%;
                    border-radius: 10px;
                }
                @include breakpoint(max-xxs_device){
                    width: 50px;
                    height: 50px;
                }
            }
        }
        .comments-info{
            .author{
                font-size: 12px;
                color: $headings-color;
            }
            .btn-reply{
                font-size: 12px;
                color: $headings-color;
                &:hover{
                    color: $primary;
                }
            }
        }
    }
}
.blog-comment-form-wrapper{
    .title{
        font-size: 24px;
        font-weight: 700;
        color: $headings-color;
    }
    .comment-box{
        .input-area{
            padding: 10px 15px;
            background: $gray-100;
            border: 0px;
            &:focus{
                border: 0px;
                outline: 0px;
                box-shadow: none;
            }
        }
    }
}
/*------- Sidebar Blog ----*/
.sidebar-wrap{
    @include breakpoint(max-md_device){
        margin-top: 90px;
    }
    @include breakpoint(max-sm_device){
        margin-top: 50px;
    }
}
.sidebar {
    float: left;
    width: 100%; 
    margin-top: 40px;
    &:first-child{
        margin-top: 0;
    }
    .sidebar-title {
        position: relative;
        padding-bottom: 10px;
        margin-bottom: 25px;
        font-size: 18px;
        text-transform: capitalize;
        color: $headings-color;
        line-height: 1;
        font-weight: 700;
        &::before{
            content: "";
            background: $headings-color;
            left: 0;
            bottom: -5px;
            width: 40px;
            height: 1px;
            position: absolute;
        }
        &::after{
            content: "";
            background: $primary;
            width: 70px;
            height: 2px;
            position: absolute;
            bottom: 0;
            left: 0px;
        }
    }
}
/*Sidebar Search*/
.sidebar-search{
    form {
        position: relative;
        max-width: 100%;
        input {
            font-size: 14px;
            width: 100%;
            border: none;
            line-height: 30px;
            padding: 10px 20px;
            padding-right: 50px;
            background-color: $gray-200;
            color: $headings-color;
            &:focus{
                border: 0;
                outline: none;
                border-radius: 0;
            }
        }
        button {
            position: absolute;
            right: 0;
            top: 0;
            background-color: transparent;
            padding: 0;
            border: none;
            color: $headings-color;
            padding: 0 17px;
            transition: .3s;
            &:hover{
                color: $primary;
                transition: .3s;
            }
            i {
                font-size: 18px;
                line-height: 50px;
                display: block; 
            }
        }
    }
}
  /*Sidebar Link*/
.sidebar-link {
    list-style: none;
    padding: 0;
    margin: 0;
    li{
        margin-top: 5px;
        &:first-child{
            margin-top: 0px;
        }
        a{
            display: block;
            position: relative;
            line-height: 30px;
            font-weight: 400;
            span{
                font-size: 85%;
            }
        }
    }
}
  /*Sidebar Blog*/
.sidebar-blog {
    list-style: none;
    padding: 0;
    margin: 0;
    li{
        display: flex;
        flex-wrap: wrap;
        max-width: 350px; 
        margin-top: 15px;
        &:first-child{
            margin-top: 0px;
        }
        .image{
            flex: 1 0 80px;
            margin-right: 15px; 
        }
        .content {
            flex: 1 0 calc(100% - 95px);
            .title {
                display: block;
                font-weight: 500;
                line-height: 1.35;
                color: $headings-color;
                &:hover{
                    color: $primary;
                }
            }
            .date {
                display: block;
                font-size: 13px;
                line-height: 1;
                margin-top: 10px; 
            }
        }
    }
}
  /*Sidebar Tags*/
.sidebar-tags {
    display: flex;
    flex-wrap: wrap;
    margin: -3px;
    a {
        font-size: 13px;
        color: $headings-color;
        background-color: transparent;
        padding: 6px 20px;
        margin: 3px;
        border: 1px solid $gray-200;
        &:hover{
            border-color: $primary;
            background-color: $primary;
            color: $white;
        } 
    }
}