/*----------------------------------------*/
/*  01. Template default CSS
/*----------------------------------------*/

@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }
    &:-moz-placeholder {
        @content;
    }
    &::-moz-placeholder {
        @content;
    }
    &:-ms-input-placeholder {
        @content;
    }
}

/*-- Google Font --*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/*-- Common Style --*/
*,
*::after,
*::before {
    box-sizing: border-box;
}
html,
body {
    height: 100%;
}
body {
    font-size: 15px;
    font-weight: 400;
    font-style: normal;

    position: relative;

    visibility: visible;

    color: $gray-1100;
    &[dir="rtl"] {
        text-align: right;
    }
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input[type=number] {
	-moz-appearance: textfield;
}
button, .btn{
    &:focus{
        outline: none;
        box-shadow: none;
    }
    &:active{
        &:focus{
            box-shadow: none;
        }
    }
}
ul {
	margin: 0;
	padding: 0;
	list-style: none;
}
img {
	max-width: 100%;
}
.img-full {
	width: 100%;
	height: 100%;
	overflow: hidden;
	display: block;
}
a{
	color: inherit;
    display: inline-block;
    line-height: inherit;
    text-decoration: none;
    transition: $baseTransition;
	&:hover{
		color: $primary;
        transition: $baseTransition;
	}
}
