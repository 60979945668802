/*----------------------------------------*/
/*    10. Component - Support
/*----------------------------------------*/
.support-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    p{
        font-size: 15px;
		max-width: 655px;
		margin: 0 auto;
		@include breakpoint(max-xl_device){
			max-width: 500px;
		}
		@include breakpoint(max-xs_device){
			font-size: 16px;
        }
    }
}